
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        














































































































$icon-size: 3.6rem;

.flexible-prepare__list {
  @extend %list-nostyle;
}

.flexible-prepare__item {
  position: relative;
  padding-left: $spacing + $icon-size;

  &:last-child {
    margin-bottom: 0;
  }
}

.flexible-prepare__item__icon {
  position: absolute;
  top: 0;
  left: 0;
  width: $icon-size;
  height: $icon-size;

  ::v-deep {
    .icon {
      fill: $c-mustard;
    }
  }
}

@include mq('s') {
  .flexible-prepare__title {
    width: col(8, 10);
  }
}

@include mq('m') {
  .flexible-prepare__list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $spacing * 2.5;
  }

  .flexible-prepare__item {
    margin-bottom: 0;
    padding-left: $spacing + $icon-size;

    &:first-child {
      padding-top: 0;
      border-top: 0;
    }
  }
}

@include mq('l') {
  .flexible-prepare {
    display: flex;
  }

  .flexible-prepare__title {
    width: col(3.5, 10);
    margin-right: col(1, 10);
  }

  .flexible-prepare__list {
    width: col(5.5, 10);
  }
}

@include mq('xl') {
  .flexible-prepare__title {
    width: col(3, 10);
  }

  .flexible-prepare__list {
    width: col(6, 10);
  }
}
